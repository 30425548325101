// Styles for Nodes
// -----------------------------------------------------------------------------
.node-article, .node-atelier {
  &.node-teaser {
    padding: 2rem 0;
    border-bottom: 1px dashed #8FB993;

    .row {
      @media(min-width: $screen-md-min){
        display: flex;
        flex-wrap: wrap;
      }
    }

    @media(min-width: $screen-md-min){
      padding: 4rem 0;
    }

    img {
      width: 100%;
      object-fit: cover;

      @media(min-width: $screen-md-min){
        height: 100%;
      }
    }

    h2 {
      padding: 0;
      margin: 0 0 2rem;
      //background-image: url("../images/puce-node.jpg");

      //background-position: left 12px;
      //background-repeat: no-repeat;
    }
    h3 {
      margin-top: 0;
    }
    .node-readmore {
      a {
        @extend .btn;
        @extend .btn-primary;
      }
    }

  }
}