// Page Layout
// -----------------------------------------------------------------------------

#region-branding {
  //background: url(../images/header-logo-bas.jpg) repeat-x bottom center;
  //padding-bottom: 20px;
  .home-link {
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: none;
    }
  }
  .site-name {
    font-family: $font-logo;
    font-size: 2.2em;
    font-weight: lighter;
    text-transform: uppercase;
    color: #000;
    @include breakpoint(xs) {
      font-size: 2.5rem;
    }
  }
}
#logo {
  width:100px;
  height:auto;
  //margin-left:5px;
}
.site-slogan {
  background-color: inherit;
  color: $ma-blue-dark;
  font-family: Verdana,Arial,Helvetica,sans-serif;
  font-size: 1.7rem;
  margin: 0;
  font-weight: normal;
  height: 20px;
  text-align: left;
  float: left;
  @include breakpoint(md) {
    margin-left: 0;
    margin-top: 44px;
    margin-bottom: 0;
    font-size: 1.9rem;
  }
}
.home-link {
  &:hover {
    text-decoration: none;
  }
}
.site-name {
  font-family: $font-logo;
  font-size: 2.5em;
  font-weight: lighter;
  text-transform: uppercase;
  color: #000;
}