/****************Page Date d'atielier***/
.node-date-atelier .submitted {
  display:none;
}
.node-date-atelier .field-name-field-date-atelier-date {
  font-size:28px;
  color:#008080;
  margin-bottom: 20px;
}
.node-date-atelier .field-label-inline {
  //font-size: 13px;
  margin-bottom: 10px;
}
.node-date-atelier .description strong {
  font-size:16px;
  color:#008080;
}
/*Form*/
#registration-form .form-item {
  margin-right: 2em;
}
#registration-form input[type="text"] {
  width: 100%;
}