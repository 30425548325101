// Page Layout
// -----------------------------------------------------------------------------
#footer {
  margin: 0;
  padding:30px 0;
  background-color: $ma-blue-dark;
  position: relative;

  a {
    color: $ma-gray;
    &:hover {
      color:$ma-blue-dark;
    }
  }

  h4 {
    color: $ma-white;
  }

  .fa {
    font-size: 25px;
  }

    //@include background-opacity($color-secondary-2-4, 0.5);
    //&::before {
    //  transform: skewX(-35deg);
    //  width: 90%;
    //  left: 5%;
    //}
}