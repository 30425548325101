/********Calendrier calendar date ****/
.calendar-calendar {

  .month-view {

    .monthview {
      font-size: 12px;
    }

    .full td .inner div.calendar.monthview div a {
    font-size: 14px;
    }
   .contents {
    padding: 5px !important;
    }
   .views-field-status {
    background-color: #fff4f4 !important;
    color: #515151;
    padding: 0 3px !important;
    }

  }
}