// Styles for Menus and Navs
// -----------------------------------------------------------------------------

$menu-color: $ma-blue;

.dropdown-menu {
  font-size: 16px;
}
.tb-megamenu {
  background-color:inherit;
}

.tb-megamenu .dropdown-menu {
  border-bottom: 3px solid $menu-color;
}
.tb-megamenu .nav > li > a {
  border-right: none;//1px solid $color-secondary-2-2;
  padding: 5px 20px;
  font-size:18px !important;
  font-weight: lighter;
  text-transform:uppercase;
  text-decoration:none;
  color: #000;
}
.tb-megamenu .dropdown-menu .active > a,
.tb-megamenu .dropdown-menu .active > a:hover {
  background-image: none;
  color: $menu-color;
}

.tb-megamenu .nav > .active > a, .tb-megamenu .nav > .active > a:hover, .tb-megamenu .nav > .active > a:focus {
  //background-color: $color-secondary-2-2;
  color: #fff;
  background-color: transparent;
  position: relative;
  z-index: 1;
  @include background-opacity($menu-color, 1);
  &::before {
    //transform: skewX(-35deg);

    width: 100%;
    left: 0;
    z-index: -1;
  }
}

.tb-megamenu .nav > li > a:focus, .tb-megamenu .nav > li > a:hover {
  color: $ma-blue-light;
}

.tb-megamenu .nav li.dropdown.open > .dropdown-toggle {
  color: $ma-blue-light;
}
.tb-megamenu .dropdown-menu li > a:hover, .tb-megamenu .dropdown-menu li > a:focus, .tb-megamenu .dropdown-submenu:hover > a {
  color: $ma-blue-light;
}

.tb-megamenu .nav li.dropdown.active > .dropdown-toggle, .tb-megamenu .nav li.dropdown.open.active > .dropdown-toggle, .tb-megamenu .nav > li.dropdown.open.active > a:hover {
  //background-color: $color-secondary-2-2;
  background-color: transparent;
  color: #fff;
}

.tb-megamenu .nav li.dropdown.active > .dropdown-toggle, .tb-megamenu .nav li.dropdown.open.active > .dropdown-toggle, .tb-megamenu .nav > li.dropdown.open.active > a:hover {
  border-color:inherit;
}
.tb-megamenu .mega-nav > li:first-child > a, .tb-megamenu .dropdown-menu .mega-nav > li:first-child > a {
  text-decoration:none;
}
.tb-megamenu .dropdown-menu li > a {
  border-top-color: #eee !important;
  background-color: white !important;
  line-height: 25px;
  font-size: 17px;
}
.tb-megamenu .mega-inner {
  padding: 0px;
}
.tb-megamenu .mega-group-title {
  margin-top: 15px !important;
  border-top: none !important;
}
/********responsive collapse*******/
.tb-megamenu .btn-navbar {
  background: $menu-color none repeat scroll 0 0 !important;
  border: none !important;
  box-shadow: none !important;
  color: #fff;
  padding: 10px;
  text-shadow: none !important;
  font-size:24px;
  margin:0;
  float:right;
}
.tb-megamenu .collapse {
  background: #000 none repeat scroll 0 0;
  margin-top: 0px;
  position: relative;
  top: 0px;
  width: 100%;
  display: block;
}
.tb-megamenu .collapse {
  background-color: $menu-color;
}
.tb-megamenu .collapse .dropdown-menu {
  background-color: #fff;
}
.tb-megamenu .collapse > li {
  border-bottom: $menu-color;
}
.tb-megamenu .collapse .nav > li > a, .tb-megamenu .collapse .dropdown-menu a {
  border: none;
}
.tb-megamenu .collapse .nav > li > a {
  background-color: $menu-color;
  color: #fff;
  font-size: 14px;
  padding: 5px 20px;
  text-decoration: none;
  text-transform: uppercase;
}
.tb-megamenu .collapse .nav > li > a:hover {
  background-color: $menu-color;
  color: #fff;
}
.tb-megamenu .collapse li.dropdown.open > .dropdown-toggle {
  background-color: $menu-color;
  color: #fff;
}
.tb-megamenu .collapse .dropdown-menu a {
  color: #333;
}