// Styles for Taxonomy
// -----------------------------------------------------------------------------

.ma-atelier-card {

  &__title {
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 150px;
      font-size: 25px;
      font-weight: normal;
      text-transform: uppercase;
      background-color: #f6fbe6;
      margin-bottom: 20px;
      border: 1px solid #f6fbe6;

      &:hover {
        border: 1px dashed #8FB993;
        text-decoration: none;
      }
    }

  }
}