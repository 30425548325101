// Page home
// -----------------------------------------------------------------------------
.front {
  .region.region-highlight {
    @media(min-width: $screen-md-min){
      height: 400px;
    }


    background: url(../images/bg_highlight_home2.jpg) no-repeat 0 0;
    background-size: cover;
    &::before {
      display: none;
    }
    .container {
      .row {

        @media(min-width: $screen-sm-max){
          display: flex;
        }
        .block-highlight {
          //top: 255px;
          //bottom: 0;
          //align-self: flex-end;


          flex-direction: column;
        }
      }
    }
  //.block-highlight {
  //  &::before {
  //    opacity: .85;
  //  }
  //}
  }
  #breadcrumb {
    display: none;
  }
  .region {
    &.region-preface-2 {
      position: relative;
      z-index: 0;
    @include background-opacity(white, 0.3);
      &::before {
        transform: skewX(35deg);
      }

    }
  }

  #section-preface-2 {
    background-color: $ma-green-light;//lighten($color-secondary-2-0, 5%);
  }

  #content {
    display: none;
  }

  h2 {
    background-image: none !important;
    margin-bottom: 10px !important;
  }
}
