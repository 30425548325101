// Page Layoutformation pro - Page fiche
// -----------------------------------------------------------------------------
.node-type-formation-pro {


  .field-name-field-document {
    padding: 20px;
    border: 1px solid gray;
    margin-top: 20px;
  }
}