///******display suite elements *******/
.ds-2col {

  & > .group-left {
    @extend .col-md-6;
    @extend .col-xs-12;
    padding: 0;
  }
  & > .group-right {
    @extend .col-md-6;
    @extend .col-xs-12;
    padding: 0;
  }
}

//
///******display suite elements *******/
//.ds-2col > .group-left {
//  float: left;
//  width: 50%;
//}
//
//.ds-2col > .group-right {
//  float: left;
//  width: 50%;
//}
//
///******display suite elements *******/
//
///*INSCRIPTION form*/
//.form-item-field-envoyer-notification-und {
//  margin-top: 35px;
//  position: absolute;
//  margin-left: 0;
//}
//
//
///*INSCRIPTION form*/
//.form-item-field-envoyer-notification-und {
//  margin-top: 35px;
//  position: absolute;
//  margin-left: -100px;
//}