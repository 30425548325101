/****************Page Ateliers*********/
.node-type-atelier fieldset.infos-comp {
  display: none;
  /*font-size: 11px;*/
  /*margin: 0 0 10px 0;*/
  /*width: 97%;*/
  /*border: 2px groove #eee;*/
  /*padding: 0.5em;*/
}

/*Block Info Complémentaires*/
.block-views-infos-compl-mentaires-block .view-infos-compl-mentaires {
  font-size: 12px;
  margin: 0 0 10px 0;
  width: auto;
  border: 2px groove #eee;
  padding: 0.5em;
}
.block-views-infos-compl-mentaires-block .views-label {
  font-weight: bold;
}
.block-views-infos-compl-mentaires-block .field-content {
  display: inline;
}
/**/
/*Block Dates Inscription */
.block-views-dates-block-1 {
  /*font-size: 11px;*/
  margin: 0 0 10px 0;
  width: auto;
  /*border: 2px groove #eee;*/
  padding: 0.5em;
  /*background: white;*/
  margin: 0 0 40px;
}
/**/
.node-type-atelier .pager .date-prev,
.node-type-atelier .pager .date-next {
  background:none;
}

.node-type-atelier .region-sidebar-second .date-heading h3{
  color:#5f305a;
  font-size:14px;
  font-weight:bold;
}
.node-type-atelier .field-name-field-images-bas .field-item {
  display:inline-block;
  width:33%;
  height: 200px;
  overflow: hidden;
  margin-bottom:10px;
}

.node-type-atelier .field-name-field-images-bas .field-item img {
  width:100%;
}