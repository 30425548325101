/*Page planning*/
//css issu du premier theme

.view-planning .view-grouping-content {
  display: inline-block;
  width: 100%;
}
.view-planning table {
  width:49.9%;
  display:inline-block;
  font-size:13px;
  border:none;
  margin:0;
}
.view-planning table caption{
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  position: absolute;
  text-align: center;
  top: 50px;
  width: 50%;
}
.view-planning tbody, .view-planning tbody tr {
  display:block;
  width:100%;
  padding:0;
  border-bottom:1px solid #E6E7C8;
  border-right:1px solid #E6E7C8;
  border-left:1px solid #E6E7C8;
  font-size:14px;
}
.view-planning table td {
  background-color:transparent;
}

/*planning message*/
/*.view-planning .unpublish .views-field-field-date-atelier-message {*/
/*background-color: #fff4f4;*/
/*color: #515151;*/
/*}*/
.view-planning .views-field-field-date-atelier-message {
  background-color: #8fb993;
  color:#fff;
}

.view-planning .views-field-field-date-atelier-message .editablefield-item {
  /*background:url(../images/comment.png) no-repeat;*/
  /*background-size: cover;*/
  position: relative;
  min-width: 20px;
  min-height: 20px;

}


.view-planning .views-field-field-date-atelier-message .editablefield-item::before{
  font-family: 'FontAwesome';
  content: "\f0e5";
  font-size:20px;

  position: absolute;
  top: -2px;
}

.view-planning .views-field-field-date-atelier-message .editablefield-item .field-widget-text-textarea {
  position: relative;
}
.view-planning .views-field-field-date-atelier-message .editablefield-item .editablefield-edit {
  text-indent:-500px;
  background:none;
  border:none;
  padding:0;
  height:100%;
  width:100%;
  position: absolute;
  top: 0;

}
/*End planning message*/

.view-planning .views-field-field-bon-numero {
  display:inline-block;
}
.view-planning .views-field-field-bon-numero .field-content::before {
  font-family: 'FontAwesome';
  content: "\f06b";
  font-size:14px;
  color:#636;
  float:left;
}
.view-planning .views-field-path a::before {
  font-family: 'FontAwesome';
  content: "\f145";
  float:left;
  font-size:20px;
}
.view-planning .views-field-field-couleur-fond,
.view-planning .views-field-field-couleur-police {
  display:none!important;
}
.view-planning .jour {
  font-size:20px;
  font-weight:bold;
}
.view-planning form div:first-child > .view-grouping {
  clear: both;
  position:relative;
  margin-bottom:10px;
  width: 100%;
  height: 100%;
}
.view-planning .view-grouping .view-grouping-header {
  font-size:28px;
  line-height:28px;
  padding:10px 0 0 10px;
  font-family:"Satisfy","Arimo",arial,sans-serif;
  text-align:left;
  margin-top:10px;
}
.view-planning .views-field-title > a:first-child {
  color: inherit;
  font-family: inherit;

  font-weight:bold;
  margin-right: 15px;
  padding:5px;
  display:inline-block;
}
.view-planning .view-grouping-content .view-grouping-header {
  text-align:center;
  font-family:inherit;
  font-size:15px;
  text-transform:uppercase;
  margin-bottom:10px;
  color:#515151;
  font-weight:bold;
}
.view-planning .form-actions {
  clear: both;
}
.view-planning .Après-midi {
  float: right;
}
.view-planning .views-field-field-date-atelier-date .unpublish::before{
  font-family: 'FontAwesome';
  font-size:17px;
  content: "\f070";
  color:#636;
  padding:0 5px;
  width: 100%;
  padding: 0;
  position: absolute;
  margin-top: 20px;
  right: 0;
}
.view-planning .unpublish .views-field-field-date-atelier-date {
  background-color: #fff4f4;
  color: #515151;
}
.view-planning .views-field-field-date-atelier-date {
  background-color: #8fb993;
  color:#FFF;
  font-size:16px;
  min-width: 50px;
  text-align: center;
  position: relative;
}
.view-planning .views-field-title {
  width: 100%;
  vertical-align:top;
  padding:1px;
}



.view-planning .views-field-field-date-atelier-message .field-name-field-date-atelier-message .field-item {
  height: 20px;
  overflow: hidden;
  text-indent:-1000px;
  background: url(../images/three-dots.svg) 5px top no-repeat;
  background-size: 55px 14px;
}
.view-planning .anim {
  float: right;
  font-size: 12px;
  padding:5px;
}
.view-planning .view-date-atelier {

  padding:2px 10px;
  display:block;
  color:#636;
}
.view-planning .view-date-atelier .fieldset-wrapper {
  background-color:#E6E7C8;
}
.view-planning .view-date-atelier .views-row {
  margin:4px 0;
}
/* Bouton ajouter une inscription ***/
a.button {
  padding:3px 10px;
  display:inline-block;
  color:#636!important;
  background-color:#fff!important;
  font-size:13px;
}
a.button:hover {
  background-color:#eee!important;
}
.view-planning .views-field-title .add-ins{
  float:right;
  margin-top:1px;
}
.view-planning .views-field-title .add-ins::after{
  font-family: 'FontAwesome';
  font-size:14px;
  float:right;
  content: "\f234";
  color:#636;
  padding:0 5px;
}


/****/

/***State***/

.view-planning .views-field.views-field-state-1 {
  display: none;
}
.view-planning .view-content {
  display:inline-block;
  width:100%;
}
.view-planning .view-footer {
  display:block;
}
.view-planning .views-field-state div::before,
.view-planning .view-footer .legendes div::before  {
  font-family: 'FontAwesome';
  font-size:14px;
  float:left;
  padding-right:5px;
  cursor:help;
  width:13px;
}
.view-planning .waitlist::before {
  content: "\f007";
  color:#FFF;
}
.view-planning .pending::before {
  content: "\f007";
  color:#F60;
}
.view-planning .pr_r_gl_::before {
  content: "\f007";
  color:#FF0;
}
.view-planning .complete::before {
  content: "\f007";
  color:#0F0;
}
.held::before {
  content: "\f007";
  color:#F00;
}

.view-planning .views-field-edit-registration .state-canceled,
.view-planning .views-field-edit-registration .state-canceled_client ,
.view-planning .views-field-edit-registration .state-canceled_complet ,
.view-planning .views-field-edit-registration .state-canceled_incomplet  {
  text-decoration:line-through;
}
.view-planning .canceled::before,
.view-planning .canceled_client::before,
.view-planning .canceled_complet::before,
.view-planning .canceled_incomplet::before {
  content: "\f235";
  color:#000;
}
.view-planning .view-footer .legendes div {
  float:left;
  padding:20px 10px;
}
/***/
.view-planning .views-field-field-commentaire {
  display: inline-block;
}
.view-planning .views-field-field-commentaire .com {
  height: 10px;
  width:13px;
  overflow: hidden;
  text-indent:-1000px;
  background: url(../images/three-dots.svg) 3px top no-repeat;
  background-size: 30px 8px;
}