// Page Layoutformation pro - home
// -----------------------------------------------------------------------------
.page-node-25372 {
  .region.region-highlight {
    background: url(../images/bg_highlight_formation.jpg) no-repeat 0 0;

    @media(min-width: $screen-md-min){
      height: 400px;
    }

    .container {
      .row {
        @media(min-width: $screen-sm-max){
          display: flex;
        }
      }
    }

  }
  #breadcrumb {
    display: none;
  }
  .region {
    &.region-preface-2 {
      position: relative;
      z-index: 0;
    @include background-opacity(white, 0.3);
      &::before {
        transform: skewX(35deg);
      }

    }
  }

  #section-preface-2 {
    background-color: $ma-green-light;//lighten($color-secondary-2-0, 5%);
  }

  #content {
    display: none;
  }
}
