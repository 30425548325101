// Styles for blocks.
// -----------------------------------------------------------------------------

.block-highlight {
  position: relative;

  //min-height: 150px;
  display: flex;
  @media(min-width: $screen-sm-max){
    left:10%;
  }

  align-self: center;
  //background-color: $ma-green-light;
  z-index: 0;
  &::before {
    content: '';
    display: flex;
    background-color: $ma-green-light;

    position: absolute;
    top: 0;
    left: -45px;
    height: 100%;
    width: 142%;
    opacity: .7;
    transform: skewx(-35deg);
    z-index: -1;


  }
  //@include background-opacity($ma-green-light, 0.5);
  &--title {
    align-self: flex-end;
    padding: 20px !important;
    margin: 15px 0 0 10% !important;


    .block__content {
      display: flex;
      flex-direction: column;
      align-content: center;
      h1 {
        margin-top: 0;
        font-family: 'Dancing Script', cursive;
        font-size: 54px;
        color:$ma-blue;
        @media(max-width: $screen-sm-max){
          font-size: 45px;
        }
      }
    }
  }
}

.block:not(.block-main) h2,
.block:not(.block-main) h2.block-title{
  padding: 0 0 0 30px;
  margin: 10px 0 0 0;
  font-size: 24px;
  //font-family: 'lovitzregular','Satisfy','Arimo', arial, sans-serif;
  /*font-family: Arial,Helvetica,sans-serif;*/
  color: $ma-blue-light;
  /*font-size: 21px;*/
  //font-size: 50px;

  background-image: url("../images/puce-node.jpg");
  background-position: left 8px;
  background-repeat: no-repeat;

  /*text-transform:uppercase;*/
  /*font-weight:bold;*/
}

.block-actus {
  position: relative;
    background-color: white;
    opacity: 0.9;
  @include ma-box-shadow();
  h4.block__title {
    margin-top: 0 !important;
  }
  .views-field-created {
    font-size: 13px;
  }
  .block__content {
    .views-row {
      margin-bottom: 10px;
  }
}

}

.block-cartes {
  &:before {
    display: inline-flex;
    content: url(../images/puzzle.svg);
    width: 55px;
  }
}

.block-session {
  &:before {
    display: inline-flex;
    content: url(../images/agenda.svg);
    width: 45px;
  }
}

.block-formateurs {
  &:before {
    display: inline-flex;
    content: url(../images/formateur.svg);
    width: 65px;
  }
}

.block--cpf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-repeat:  no-repeat ;
  background-position: 50% 0;
  background-image: url(../images/atelier_51.jpg);
  background-size: cover;
  margin: 0 !important;

  .block__content{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

  .block__title {
    font-size: 25px !important;
    line-height: auto !important;
  }
  .cpf {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
    width: 100%;
    @include background-opacity(white, 0.7);
    //@include ma-box-shadow();
    .links {
      margin-bottom: 20px;
    }
  }
}


.block-ciso {
  &:before {
    display: inline-flex;
    content: url(../images/ciso2.svg);
    width: 50px;
  }
}

.block-pedago {
  &:before {
    display: inline-flex;
    content: url(../images/pedagogie.svg);
    height: 50px;
    width: 50px;
  }
}

.block-catalog {
  &:before {
    display: inline-flex;
    content: url(../images/catalog.svg);
    width: 30px;
  }
}

.block-certif {
  //background-color: white;
  .block__title:before {
    display: inline-flex;
    content: url(../images/certificat5.svg);
    width: 40px;
    vertical-align: middle;
    margin-right: 10px;

  }
}

.block-courtes {
  //background-color: white;
}

.block-15ans {
  &:before {
    display: inline-flex;
    content: url(../images/cake.svg);
    width: 40px;
  }
}

.block-100prc {
  &:before {
    display: inline-flex;
    content: url(../images/100.svg);
    width: 40px;
  }
}

.block-satisfaction {
  &:before {
    display: inline-flex;
    content: '';
    background-image: url(../images/star.svg);
    width: 80px;
    height: 20px;
    background-size: contain;
  }
}

.region.region-preface-3 {
  .block__title {
    font-size: 25px;
    font-weight: bold;
    color: $ma-green;
  }
  font-size: 16px;
}

.region.region-preface-1 {
  padding: 0;
  .block {
    margin: 0;
  }
  .block__title {
    font-size: 20px;
    //font-weight: bold;
    color: $ma-blue-dark;
  }
}

.block-back {
  a {
    //color: white;
    &:before {
      display: inline-flex;
      content: url(../images/catalog.svg);
      width: 30px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}

.block--filtres {
  margin-top: 50px;
  li {
    list-style: none;
    font-weight: lighter;
  }
}



/*//HOME BLOCKS*/
.block-ecole {
  &:before {
    display: inline-flex;
    content: url(../images/machine2.svg);
    width: 60px;
    margin-bottom: 20px;
  }
}

.block-loisirs {
  &:before {
    display: inline-flex;
    content: url(../images/ciso2.svg);
    width: 50px;
    margin-bottom: 20px;
  }
}

.block-shop {
  &:before {
    display: inline-flex;
    content: url(../images/basket2.svg);
    width: 55px;
    margin-bottom: 20px;
  }
}

.block-home-parcours {
  &:before {
    display: inline-flex;
    content: url(../images/puzzle.svg);
    width: 55px;
  }
}

.block--prive {
  &:before {
    display: inline-flex;
    content: url(../images/prive.svg);
    width: 50px;
  }
}

.block--en-détails,
.block--inscription-et-tarifs {

  margin-bottom: 30px;

  > h4 {
    position: relative;
    background-color: $ma-green;
    color: white;
    margin: 0;
    padding: 5px 15px;
    width: auto;
    display: inline-flex;
  }
  .block__content {
    padding: 20px;
    border: 2px solid  $ma-green;
  }

  .views-field {
    margin-bottom: 15px;

    .views-label {
      font-weight: bold;
      display: inline-flex;
      margin-right: 10px;
    }

    .field-content {
      display: inline-flex;
    }
  }


}