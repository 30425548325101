// Region Layout
// -----------------------------------------------------------------------------

#region-menu {
  //margin: 0;
  //width:100%;
  //z-index:999;
  background-color: transparent;//$color-secondary-2-2;
  padding: 0;
  margin-bottom: 0;
  margin-top: -15px;
  @media(max-width: $screen-sm-max){
    margin-top: -50px;
  }
}

.region {
  position: relative;

  &.region-highlight,
  &.region-preface-1,
  &.region-preface-2,
  &.region-preface-3,
  &.region-preface-4 {
    padding-top: 4rem;
    //text-align: center;
    @include breakpoint(md) {
      justify-content: space-evenly;
      display: flex;
    }
    .block {
      margin: 15px 0;
      padding: 30px;
      &__title {
        @extend h3
      }
    }
  }
  &.region-highlight {
    height: auto;
    background: url(../images/bg_highlight.jpg) no-repeat 0 0;
    background-size: cover;
    z-index: 0;
    @include background-opacity(white, 0.4);
    &::before {
      transform: skewX(-35deg);
      width: 60%;
    }
    //@media(max-width: $screen-sm-max){
    //  max-height: 200px;
    //}
    .container {
      .row {
        height: 100%;
        .block {

        }
      }
    }
    .page-title {
      margin-top: 0;
      font-family: 'Dancing Script', cursive;
      font-size: 54px;
      color: #18444b;
      margin-bottom: 20px;
    }
  }
  &.region-preface-1 {
    //background-color: #eee;
    @include ma-border-block-dashed;
  }

  &.region-preface-2 {
    padding: 0;
  }

  &.region-preface-3 {
    padding: 0;
    margin-bottom: -30px;

    @include ma-border-block-dashed;

    .block {
      margin: 0;
      @include breakpoint(md) {
        padding: 100px 30px;
      }
    }

  }

  &.region-footer-second {
    float: right;
  }

}
