/****************Page entrée COURS LOISIRS*********/
///ateliers-créatifs-à-la-carte
.page-node-6 {

  .region.region-highlight {
    //background: url(../images/bg_highlight_formation.jpg) no-repeat 0 0;

    @media(min-width: $screen-md-min){
      height: 400px;
    }

    .container {
      .row {
        @media(min-width: $screen-sm-max){
          display: flex;
        }
      }
    }

  }

  .menu-block-wrapper.parent-mlid-615 {

    ul {
      @extend .row;
      margin-top: 60px;

      li {
        @extend .col-sm-6;

        text-align: center;

        &.menu-link-dates{
          a {
            background-color: $ma-blue-light;
            color: $ma-white;
          }
        }

        &.menu-link-couture {
        }
        a {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 150px;
          font-size: 25px;
          text-transform: uppercase;
          background-color: $ma-green-light;
          margin-bottom: 20px;
          //box-sizing: content-box;

          border: 1px solid $ma-green-light;
          &:hover {
            border: 1px dashed $ma-green;
          }

          i {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;

            //&.icon-couture {
            //
            //  &::before {
            //    display: -ms-inline-flexbox;
            //    display: inline-flex;
            //    content: url(../images/machine2.svg);
            //    width: 50px;
            //    margin-bottom: 20px;
            //  }
            //}

          }
        }
      }
    }
  }
}
