// Page Layout
// -----------------------------------------------------------------------------
html {
  overflow: hidden;
}
#breadcrumb {
  display: none;
}
.page-header {
  display: none;
}

#page-title {
  margin-bottom: 20px;
}

#main {
  background-color: $ma-light;
  aside {
    margin-top: 25px;
  }
  #content {
    margin-top: 30px;
  }
}

section {
  //padding: 4rem 0;
}