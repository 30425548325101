// Mixins
// -----------------------------------------------------------------------------
@mixin background-opacity($color,$opacity) {
  &::before {
    content: '';
    display: flex;
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    background-color: $color;
    opacity: $opacity;
    z-index: -1;
  }
}

@mixin ma-box-shadow() {
  box-shadow: 7px 7px 0px 0px rgba(50, 50, 80, 0.16);
}

@mixin ma-box-bevel($color){
  background: linear-gradient(135deg, transparent 30px, $color 0);
}

@mixin ma-border-block-dashed() {
  .block {

    border-bottom: 1px dashed $ma-green;
  }

  @include breakpoint(md) {
    .block {
      border: none;
      border-left: 1px dashed $ma-green;
      box-sizing: initial;
      background-clip: content-box;
    }
    .block:last-child {
      border-right: 1px dashed $ma-green;
    }
  }
}