// Styles for comment.
// -----------------------------------------------------------------------------
.block-comment-block {
  //background-image: url("../images/inter-nodes.jpg");
  //background-position: left top;
  //background-repeat: repeat-x;
  clear: both;
  display: block;
  margin: 0 0 40px;
  padding: 40px 0 18px;

  #comments h3 a {
    font-weight:bold;
    font-size: 14px;
    margin: 20px 0 0;
    padding: 0;
    &:hover {
      color: $color-primary-0;
    }
  }

  fieldset {
    clear:both;
    margin: 20px 0;
    legend {
      float: none;
      margin-bottom: 30px;
      border: none;
      font-size: 20px;
    }
    article {

    }
  }
}


