// Page Layoutformation pro - home
// -----------------------------------------------------------------------------
.page-formation-pro-catalogue-formations {
  #page-title {
    &:before {
      display: inline-flex;
      content: url(../images/catalog.svg);
      width: 35px;
      position: relative;
      top: 5px;
      margin-right: 10px;
    }
  }
.view-header {
  margin-bottom: 30px;
}

  article {
    margin-bottom: 40px;
    .wrapper {
      padding: 15px;
      //border: 1px solid $ma-green-light;
      background-color: lighten($ma-green-light, 10%);
    }

    h2 {
      margin: 20px 0 0;
    }
    h3 {
      margin: 10px 0;
    }
.field-name-field-formation-image {
  position: relative;
  height: 100px;
  overflow: hidden;
  margin: 8px 0;
  img {
    position: relative;

    width: auto;
    height: 100%;
  }
}


    div.line-details {
      background-color: $ma-green;
      display: table;
      width: 100%;
      padding-left: 10px;

      div {
        display: table-cell;
        width: auto;
        color: white;
        &.cpf {
          border-radius: 3px;
          background-color: $ma-blue;
          color: white;
          text-align: center;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }

}

.type-tag, .fp-tag {
  border-radius: 3px;
  background-color: $ma-green-light;
  //color: white;
  text-align: center;
  width: auto;
  display: inline-flex;
  padding: 0 10px;
  font-size: 13px;
  float: left;
}