//Matiere-active Custom colors
$ma-white : white;
$ma-green : #8FB993;
$ma-gray:#dddccf;
$ma-green-light: #f6fbe6; //#ddd9bd;//#e6e7c8;
$ma-light: #fff;
$ma-blue: #18444b;
$ma-blue-light: #325D61;
$ma-blue-dark: #0A2D30;
$ma-red: #DA2F6E;


// As hex codes */
$color-primary-0: #CA064F;	// Main Primary color */
$color-primary-1: #E3578A;
$color-primary-2: #DA2F6E;
$color-primary-3: #A0003B;
$color-primary-4: #7D002E;

$color-secondary-1-0: #D36647;	// Main Secondary color (1) */
$color-secondary-1-1: #FFB8A3;
$color-secondary-1-2: #F59173;
$color-secondary-1-3: #B54626;
$color-secondary-1-4: #8F2A0D;

$color-secondary-2-0: #7F308A;	// Main Secondary color (2) */
$color-secondary-2-1: #BA7DC2;
$color-secondary-2-2: #18444b;
$color-secondary-2-3: #6B1B77;
$color-secondary-2-4: #540A5E;

$color-complement-0: #82BF40;	// Main Complement color */
$color-complement-1: #C5EE98;
$color-complement-2: #A5DD68;
$color-complement-3: #66A323;
$color-complement-4: #49810B;


